import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { navigate, PageProps } from 'gatsby';
import React, { useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import { useLocation, WindowLocation } from '@reach/router';
import { useTreatments } from '@splitsoftware/splitio-react';
import { ServerError } from '@apollo/client';
import { Helmet } from 'react-helmet';

import { isAgent } from 'src/api/client';
import { Profile } from 'src/api/types';
import useProfile from 'src/api/useProfile';
import useMembership from 'src/api/useMembership';
import useUpdateProfile, { UpdateProfileInput } from 'src/api/useUpdateProfile';
import useActiveProduct from 'src/api/useActiveProduct';
import { useMembershipStarted } from 'src/api/useMembershipStarted';
import { trackEvent, trackPageLoad } from 'src/services/tracker';
import { useMfaLogin } from 'src/hooks/use-mfa-login';
import { useAuthContext } from 'src/components/AuthProvider';
import ContactInformationForm, { ContractFormData } from 'src/components/ContactInformationForm';
import { formatValue, parseValue } from 'src/components/designSystems/Form/DateInput';
import LoadingContainer from 'src/components/LoadingContainer';
import { Banner, ChangePlanWidget, FreeTrialWidget } from 'src/components/molecule';
import { MembershipWidget } from 'src/features/checkout-optimization/membership-widget';
import { BannerSettings } from 'src/components/molecule/Banner';
import ShelbyFooter from 'src/components/organism/ShelbyFooter/ShelbyFooter';
import { isReady } from 'src/components/SplitContext/isReady';
import { Split, Treatment } from 'src/constants/Split';

import getApiErrorMessage from 'src/helpers/getErrorMessage';
import logTrackedEvent from 'src/helpers/logTrackedEvent';
import mapJoiErrorsToRHF from 'src/helpers/mapJoiErrorsToRHF';
import usePreventMemberCheckout from 'src/hooks/usePreventMemberCheckout';
import { useUserTracker } from 'src/hooks/useUserTracker';
import { useShelbyCustomer } from 'src/hooks/useShelbyCustomer';
import { useFreeTrial } from 'src/hooks/useFreeTrial';
import { usePurchaseFlowOptimization } from 'src/hooks/usePurchaseFlowOptimization';
import { useMembershipLevel } from 'src/hooks/use-membership-level';

import useCheckTermsConditionsStatus from 'src/api/useCheckTermsConditions';
import { useByPartnerCustomerIdMembership } from 'src/api/useByPartnerCustomerIdMembership';
import { HdcMembershipStatus } from 'src/constants/HdcMembershipStatus';
import StateFarmPageTemplate from 'src/components/templates/StateFarmPageTemplate/StateFarmPageTemplate';
import ShelbyExistingUser from 'src/components/molecule/ShelbyExistingUser/ShelbyExistingUser';
import TermsConditions from 'src/components/organism/TermsConditions/TermsConditions';
import allStates from 'src/models/contentful-states.json';
import LicensedState from 'src/components/checkout/LicensedState';
import { Translate } from 'src/components/Translate';
import { formatTestExperience } from 'src/helpers/formatTestExperience';
import { PlanType } from 'src/constants/Tealium';
import { CUSTOMER_EMAIL_SKIPPED } from 'src/constants/email';
import { SimpleFooter } from 'src/features/footer';

import styles from './index.module.scss';

const metadata = {
  title: 'Checkout Address Information | Hagerty Drivers Club',
  sfTitle: 'Checkout Member Information | HDC for State Farm',
  description: 'Enter your address to receive our welcome kit and award winning magazine.',
};

const i18n = {
  genericError: 'generic.error.message',
  buttonUpdateText: 'profile.mailingAddressForm.buttonUpdate.text',
  customerEmailBanner: 'checkout.information.error.banner.customerEmail',
};

const profileToFrom = (profile: Profile, consentEmail: string): ContractFormData => {
  const phoneType = () => {
    if (!profile) return 'Home';
    if (!profile.phoneMobile) return 'Home';
    if (!profile.phoneHome) return 'Mobile';
    if (!profile.phonePreferred) return 'Home';
    return profile.phoneMobile === profile.phonePreferred ? 'Mobile' : 'Home';
  };
  return {
    firstName: profile?.givenName || null,
    lastName: profile?.familyName || null,
    birthdate: profile?.birthdate ? formatValue(parse(profile.birthdate, 'yyyy-MM-dd', new Date())) : null,

    city: profile?.city || null,
    state: profile?.province || null,
    addressLine1: profile?.address?.addressLine1 || null,
    addressLine2: profile?.address?.addressLine2 || null,
    postalCode: profile?.address?.postalCode || null,
    attn:
      profile?.address?.attn || (profile?.givenName && profile?.familyName)
        ? `${profile.givenName} ${profile.familyName}`
        : null,

    phonePreferred: profile?.phonePreferred || '',
    phoneType: phoneType(),
    phoneHome: profile?.phoneHome || '',
    phoneMobile: profile?.phoneMobile || '',
    email: consentEmail || profile?.email,
  };
};

const formToProfile = (data: ContractFormData, hasDateOfBirthField: boolean): UpdateProfileInput => {
  const profile = {
    givenName: data.firstName,
    familyName: data.lastName,

    address: {
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      postalCode: data.postalCode,
      attn: data.attn,
    },
    city: data.city,
    province: data.state,
    phonePreferred: data.phonePreferred,
    phoneHome: data.phoneHome,
    phoneMobile: data.phoneMobile,
    email: data.email,
  };

  if (hasDateOfBirthField) {
    profile['birthdate'] = data.birthdate && format(parseValue(data.birthdate), 'yyyy-MM-dd');
  }

  return profile;
};

const normalizeInfo = (data: ContractFormData) => {
  return {
    address: {
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2 || null,
      postalCode: data.postalCode,
    },
    city: data.city,
    province: data.state,
    phoneHome: data.phoneHome || null,
    phoneMobile: data.phoneMobile || null,
  };
};

const Information: React.FC<PageProps> = () => {
  // Redirect to hdc-web
  useEffect(() => {
    if (!isAgent) {
      window.location.href = `${process.env.HAGERTY_ROOT_URL}/drivers-club/member/checkout/information`;
    }
  }, []);

  if (!isAgent) return <LoadingContainer height={500} />;

  const location = useLocation() as WindowLocation<{ consentEmail: string }>;
  const { loading, isAuthenticated, brokers, userId } = useAuthContext();
  const [values, setValues] = useState<UpdateProfileInput>();
  const { status: termsStatus, loading: termsLoading, refetch: termsRefetch } = useCheckTermsConditionsStatus();
  const [showTerms, setShowTerms] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);
  const updateProfile = useUpdateProfile(values);
  const { data: profile, error: profileError, ...profileQuery } = useProfile({ fetchPolicy: 'network-only' });
  const { loading: membershipLoading, hasLegacyMembership } = useMembership();
  const user = useUserTracker();
  const { banner, setBanner } = useShelbyCustomer();
  const [hadFreeTrialBanner, setHadFreeTrialBanner] = useState<BannerSettings<undefined>>();
  const [hasMembershipBanner, setHasMembershipBanner] = useState<BannerSettings<undefined>>();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const treatments = useTreatments([Split.featureConsentToMarket, Split.FeatureCreateAccountCheckoutFlow]);
  const hasCreateAccountCheckoutFlow = treatments[Split.FeatureCreateAccountCheckoutFlow].treatment === Treatment.On;
  !isAgent && useMembershipStarted();
  const level = useMembershipLevel();
  const login = useMfaLogin();

  const { data: byPartnerCustomerIdData, loading: byPartnerCustomerIdDataLoading } = useByPartnerCustomerIdMembership();
  const customerHasMembership =
    byPartnerCustomerIdData?.assets?.length && byPartnerCustomerIdData?.assets[0].status === HdcMembershipStatus.ACTIVE;

  const { product } = useActiveProduct();
  const mileages = product?.mileages || [];

  const splitIsReady = isReady();
  const { hasFreeTrial, hadFreeTrialProduct, isEligibleForFreeTrial, freeTrialIsReady } = useFreeTrial();
  // New purchase flow
  const { hasNewPurchaseFlow } = usePurchaseFlowOptimization();
  const hasDateOfBirthField = profile && !profile.hasDateOfBirth && !isAgent;

  useEffect(() => {
    // Send user to Identity Create Account page
    if (!loading && !isAuthenticated && splitIsReady && hasCreateAccountCheckoutFlow && !hasNewPurchaseFlow) {
      login({ acr_values: 'register' });
    }
    // Authenticate user without feature_purchase_flow_optimization flag
    else if (!loading && !isAuthenticated && splitIsReady && !hasNewPurchaseFlow) {
      login();
    }
  }, [loading, isAuthenticated, splitIsReady, hasCreateAccountCheckoutFlow, hasNewPurchaseFlow]);

  !isAgent && usePreventMemberCheckout();

  useEffect(() => {
    if (isAgent && (profileError?.networkError as ServerError)?.statusCode === 403) {
      navigate('/agent-unauthorized');
    }
  }, [profileError]);

  useEffect(() => {
    if (hadFreeTrialProduct) {
      setHadFreeTrialBanner({
        display: true,
        message: 'checkout.informationPage.hadFreeTrialBanner',
        color: 'unknown',
        rounded: true,
        isNotDismissable: true,
      });
    } else {
      setHadFreeTrialBanner(null);
    }
  }, [profile, profileQuery.loading, hadFreeTrialProduct]);

  const defaultValues = useMemo<ContractFormData>(() => {
    return profileToFrom(profile, location?.state?.consentEmail);
  }, [profile, profileQuery.loading]);

  async function onSubmit(
    data: ContractFormData
  ): Promise<true | string[] | Record<string, { type: string; message: string }>> {
    const v = formToProfile(data, hasDateOfBirthField);
    setValues(v);
    const oldValues = normalizeInfo(defaultValues);
    const newValues = normalizeInfo(data);
    const equal = isEqual(newValues, oldValues);
    let tagName = isAgent ? 'hdc_membership_info' : 'checkout_info_continue';
    if (!equal && !isAgent && defaultValues.postalCode !== null) {
      tagName = 'checkout_info_continue_edited';
    }

    try {
      if (hasNewPurchaseFlow) {
        if (isAuthenticated) data = { ...data, email: profile?.email };

        await fetch(`${process.env.BFF_URL}/redirect?email=${data.email}`)
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            } else if (response.status === 403) {
              setHasMembershipBanner({
                display: true,
                message: 'checkout.information.hasMembershipBanner',
                color: 'failure',
                rounded: true,
                isNotDismissable: true,
                icon: 'alerts-tip',
              });
              return;
            } else return Promise.reject(response);
          })
          .then(async (responseData) => {
            if (isAuthenticated) await updateProfile();

            const redirect = isAuthenticated ? '/benefits?show_modal=true' : responseData.redirect;
            navigate(`/checkout/payment/information/${location.search}`, {
              state: { profile: data, redirect },
            });
          });
      } else {
        const { data: res } = await updateProfile();
        const updatedProfile = res.updatedProfile.data;
        const plan = mileages[level - 1];
        const freeTrialExperiment = formatTestExperience(Split.FeatureFreeTrial, isEligibleForFreeTrial);

        logTrackedEvent(trackEvent, {
          _tag_name: tagName,
          ...user,
          product_code: plan?.name,
          commerce_product: [plan?.name],
          zip_code: updatedProfile.address.postalCode,
          test_experience: [freeTrialExperiment],
          action: 'button',
          action_name: 'Continue',
        });

        navigate(`/checkout/payment/${location.search}`, { state: { omniId: res.updatedProfile?.data?.id } });
      }

      return true;
    } catch ({ networkError, message }) {
      let errorMsg: string[] = [i18n.genericError];
      if (networkError?.result?.error) {
        const resultError = networkError.result.error;
        const { code, details } = resultError;

        // ADO #20666: If there is details prop, and it's an array, map to local fields
        if (code === 1107) {
          const rhfErrors = mapJoiErrorsToRHF(details);
          const errors = rhfErrors.reduce((s, { name, type, message }) => {
            s[name] = { type, message };
            return s;
          }, {});
          logTrackedEvent(trackEvent, {
            alert_details: JSON.stringify(errors),
          });
          return errors;
        } else {
          // Otherwise, fallback to use errorMsg to show error
          const errors = getApiErrorMessage('put', 'api.contact.external.users.profile.me', networkError.result.error);
          logTrackedEvent(trackEvent, {
            alert_details: JSON.stringify(errors),
          });
          return errors;
        }
      } else if (message) {
        errorMsg = [message];
      }
      logTrackedEvent(trackEvent, {
        alert_details: JSON.stringify(errorMsg),
      });
      return errorMsg;
    }
  }

  const getAgentState = () => {
    return allStates.find((state) => state.code === String(brokers.agencyId).slice(0, 2));
  };

  // Pageload view
  useEffect(() => {
    if (user && !isPageLoading && !hasLoaded && !profileQuery.loading && splitIsReady && mileages.length > 0) {
      const agentState = isAgent && profile ? getAgentState() : null;

      if (isAgent && !agentState) {
        trackPageLoad({ _tag_name: 'sf_state_code_error', ...user, virtual_page_name: 'state-code-error' });
      } else {
        const plan = mileages[level - 1];
        const freeTrialExperiment = formatTestExperience(Split.FeatureFreeTrial, isEligibleForFreeTrial);
        trackPageLoad({
          _tag_name: 'checkout_info',
          ...user,
          product_code: plan?.name,
          commerce_product: [plan?.name],
          plan_type: PlanType.New,
          previous_hdc_free_trial: !!hadFreeTrialProduct,
          test_experience: [freeTrialExperiment],
          virtual_page_name: 'member-information',
        });
      }

      setHasLoaded(true);
    }
  }, [user, hasLoaded, product, profileQuery, splitIsReady, isPageLoading]);

  useEffect(() => {
    setIsPageLoading(
      loading ||
        termsLoading ||
        !splitIsReady ||
        (!isAuthenticated && !hasNewPurchaseFlow) ||
        (isAgent && !profile) ||
        (isAgent && byPartnerCustomerIdDataLoading) ||
        (!isAgent && membershipLoading) ||
        hasLegacyMembership
    );
  }, [
    loading,
    termsLoading,
    splitIsReady,
    isAuthenticated,
    hasNewPurchaseFlow,
    isAgent,
    profile,
    byPartnerCustomerIdDataLoading,
    membershipLoading,
  ]);

  const params = new URLSearchParams(location.search);

  useEffect(() => {
    if (
      byPartnerCustomerIdData?.errors?.length &&
      byPartnerCustomerIdData?.errors[0]?.__typename === HdcMembershipStatus.MEMBER_NOT_FOUND
    ) {
      navigate('/agent-unauthorized');
    } else if (
      splitIsReady &&
      treatments[Split.featureConsentToMarket].treatment === Treatment.On &&
      termsStatus === 'VALID' &&
      userId &&
      !byPartnerCustomerIdDataLoading &&
      byPartnerCustomerIdData &&
      !customerHasMembership &&
      !params.get('consent-skipped')
    ) {
      if (
        byPartnerCustomerIdData?.associatedAgent?.allowedToSell &&
        !byPartnerCustomerIdData?.marketingConsent?.needsEmailMarketingConsent
      ) {
        return;
      } else {
        navigate(`/state-farm/consent?userId=${userId}`);
      }
    }
  }, [splitIsReady, termsStatus, userId, termsLoading, byPartnerCustomerIdData, byPartnerCustomerIdDataLoading]);

  /**
   * Display error Banner if agent has been redirected from /checkout/payment page
   * due to customer not having an email address saved in Omni
   */
  useEffect(() => {
    const hasCustomerEmailParam = params.get(CUSTOMER_EMAIL_SKIPPED);
    if (hasCustomerEmailParam) {
      setBanner({
        display: true,
        message: i18n.customerEmailBanner,
        color: 'failure',
      });
    }
  }, []);

  const renderAgentValidity = () => {
    if (isAgent && showTerms && termsStatus !== 'VALID') {
      return (
        <StateFarmPageTemplate showFooterDisclaimer={false}>
          <TermsConditions
            successCallback={() => {
              setShowTerms(false);
              termsRefetch();
            }}
          />
        </StateFarmPageTemplate>
      );
    }

    if (isAgent && profile) {
      const agentState = getAgentState();
      const customerState = allStates.find((state) => state.abbreviation === profile.province);

      if (!agentState) {
        return (
          <StateFarmPageTemplate>
            <div className="container main">
              <div className="status-info">
                <h4>
                  <Translate resourceKey="checkout.agent.badAgencyFormat.title" />
                </h4>
                <p>
                  <Translate resourceKey="checkout.agent.badAgencyFormat.description" />
                </p>
              </div>
            </div>
          </StateFarmPageTemplate>
        );
      }

      // Consent Feature - remove this conditional and component as it will no longer be needed in flow when checking
      if (agentState.id !== customerState.id || agentState.licenceRequired) {
        return <LicensedState />;
      }
    }

    if (isAgent && customerHasMembership) {
      return <ShelbyExistingUser />;
    }

    return renderInformationPage();
  };

  const renderInformationPage = () => (
    <>
      <Helmet>
        <title>{isAgent ? metadata.sfTitle : metadata.title}</title>
        {!isAgent && <meta name="description" content={metadata.description} />}
      </Helmet>
      <div className={hasNewPurchaseFlow ? styles.hasNewPurchaseFlow : ''}>
        {banner?.display && <Banner banner={banner} setBanner={setBanner} noTimeout />}
        {freeTrialIsReady ? (
          <div className="stack-l">
            {isEligibleForFreeTrial || hasNewPurchaseFlow ? (
              <>
                {hasNewPurchaseFlow && hasMembershipBanner?.display && (
                  <div className={`container container_center information ${styles.hasNewPurchaseFlowBanner}`}>
                    <div className="status-info__min-height">
                      <div data-cy="has-membership-banner">
                        <Banner banner={hasMembershipBanner} setBanner={setHasMembershipBanner} noTimeout />
                      </div>
                    </div>
                  </div>
                )}
                <div className="container container_center information">
                  <div className={styles.sidebarLayout}>
                    <ContactInformationForm
                      defaultValues={defaultValues}
                      onSubmit={onSubmit}
                      hasDateOfBirthField={hasDateOfBirthField}
                    />
                    <div className="display-mobile-none">
                      {isEligibleForFreeTrial && !hasNewPurchaseFlow && <FreeTrialWidget />}
                      {hasNewPurchaseFlow && <MembershipWidget hasFreeTrial={isEligibleForFreeTrial} />}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="container container_center information">
                  <div className="status-info__min-height">
                    {hasFreeTrial && hadFreeTrialProduct && hadFreeTrialBanner?.display && (
                      <div className={styles.hadFreeTrialBanner} data-cy="had-free-trial-banner">
                        <Banner banner={hadFreeTrialBanner} setBanner={setHadFreeTrialBanner} noTimeout />
                      </div>
                    )}
                    <ChangePlanWidget changeUrl={'/checkout/'} />
                  </div>
                </div>
                <div className="container container_center information">
                  <ContactInformationForm
                    defaultValues={defaultValues}
                    onSubmit={onSubmit}
                    hasDateOfBirthField={hasDateOfBirthField}
                  />
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="full-page-loading">
            <LoadingContainer />
          </div>
        )}
        {isAgent ? (
          <div className="shelby-separator container container_center information">
            <ShelbyFooter />
          </div>
        ) : (
          <SimpleFooter />
        )}
      </div>
    </>
  );

  if (isPageLoading) {
    return (
      <div className="full-page-loading">
        <LoadingContainer />
      </div>
    );
  }

  return renderAgentValidity();
};

export default Information;
